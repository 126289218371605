import React from "react";
import { ChatWindow } from "../components/ChatWindow";
import { AppCallbackContext, useAppStreamCallbacks } from "../hooks/useStreamCallback";
import { useStreamLog } from "../hooks/useStreamLog";

export function Chat(props) {
  const {theme, toggleTheme} = props;
  const { context, callbacks } = useAppStreamCallbacks();
  const { startStream, stopStream } = useStreamLog(callbacks);
  return (
    <div className="flex items-center flex-col text-ls-black bg-background">
      <AppCallbackContext.Provider value={context}>
        <div className="flex flex-col h-[calc(100dvh-85px)] w-screen max-w-4xl m-auto">
          <ChatWindow
              startStream={startStream}
              stopStream={stopStream}
              messagesInputKey={"messages"}
              inputKey={"input"}
              theme={theme}
            ></ChatWindow>
        </div>
      </AppCallbackContext.Provider>
    </div>
  );
}

export default Chat;

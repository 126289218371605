import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  DropdownSection,
  Button,
} from "@nextui-org/react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faMoon } from "@fortawesome/free-solid-svg-icons";
import { DropdownWrapper, InputWrapper, SearchInput } from "./SearchBoxStyles";
import { useNavigate } from "react-router-dom";
import { NavigatePage } from "../../../helpers/NavigatePage";
import { addFullStop } from "../../../helpers/TextConverters";

const SearchBox = ({ toggleTheme }) => {
  const [selectedKeys, setSelectedKeys] = React.useState(
    new Set(["filter_search"])
  );
  const [keyword, setKeyword] = React.useState("");
  const navigate = useNavigate();

  const selectedValue = React.useMemo(
    () => Array.from(selectedKeys).join(", ").replaceAll("_", " "),
    [selectedKeys]
  );

  const onSubmit = () => {
    const selectedKey = Array.from(selectedKeys)[0];
    let localKeyword;
    if (keyword === "") {
      localKeyword = "no_keyword_entered";
      return NavigatePage(navigate, `/search/${selectedKey === "filter_search" ? "any" : selectedKey}/${localKeyword}/`);
    } else {
      localKeyword = addFullStop(keyword);
      localKeyword = encodeURIComponent(localKeyword.toLowerCase());
    }
    if (selectedKey === "filter_search") {
      return NavigatePage(navigate, `/search/any/${localKeyword}/`);
    } else {
      return NavigatePage(navigate, `/search/${selectedKey}/${localKeyword}/`);
    }
  };
  return (
    <div>
      <InputWrapper>
        <SearchInput
          label="Search"
          placeholder="Enter Genus, Infraclass or Customary Use"
          value={keyword}
          onValueChange={setKeyword}
        />
      </InputWrapper>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            padding: "20px",
          }}
        >
          <Button onClick={()=>{navigate("/")}}>Chat</Button>
          <DropdownWrapper>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  label="Filter Search"
                  className="capitalize"
                  color="primary"
                  style={{ width: "200px" }}
                >
                  {selectedValue}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Single selection actions"
                variant="flat"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
              >
                <DropdownSection title="Any" showDivider>
                  <DropdownItem key="any">Any</DropdownItem>
                </DropdownSection>
                <DropdownSection title="By plant" showDivider>
                  <DropdownItem key="genus">Genus</DropdownItem>
                  <DropdownItem key="plant_order">Order</DropdownItem>
                  <DropdownItem key="plant_family">Family</DropdownItem>
                </DropdownSection>
                <DropdownSection title="By chemical" showDivider>
                  <DropdownItem key="infraclass">Infraclass</DropdownItem>
                  <DropdownItem key="chemical_class">Class</DropdownItem>
                  <DropdownItem key="chemical_subclass">Subclass</DropdownItem>
                </DropdownSection>
                <DropdownSection title="By usage">
                  <DropdownItem key="customary_use">Customary Use</DropdownItem>
                </DropdownSection>
              </DropdownMenu>
            </Dropdown>
          </DropdownWrapper>
          <Button
            startContent={<FontAwesomeIcon icon={faMagnifyingGlass} />}
            color="success"
            style={{ width: "300px", color: "white" }}
            onClick={onSubmit}
          >
            Search
          </Button>
          <Button isIconOnly onClick={toggleTheme}>
            <FontAwesomeIcon icon={faMoon} />
          </Button>
        </div>
      </div>
    </div>
  );
};
export default React.memo(SearchBox);

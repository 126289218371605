import ChatHeader from "../ChatHeader/ChatHeader";
import Chat from "../../features/chatbot/pages/Chat";

const ChatPanel = ({ theme, toggleTheme }) => {
//   document.title = "CMFoA Network Diagram";
  return (
    <div className="">
      <div className="border-b">
        <ChatHeader theme={theme} toggleTheme={toggleTheme} />
      </div>
      <div className="">
        <Chat theme={theme}/>
      </div>
    </div>
  );
};

export default ChatPanel;

import {
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";

export const AppCallbackContext = createContext(null);

export function useAppStreamCallbacks() {
  // callbacks handling
  const context = useRef({ onStart: [], onChunk: [], onSuccess: [], onError: [] });

  const callbacks = {
    onStart(...args) {
      for (const callback of context.current.onStart) {
        callback(...args);
      }
    },
    onChunk(...args) {
      for (const callback of context.current.onChunk) {
        callback(...args);
      }
    },
    onSuccess(...args) {
      for (const callback of context.current.onSuccess) {
        callback(...args);
      }
    },
    onError(...args) {
      for (const callback of context.current.onError) {
        callback(...args);
      }
    },
  };

  return { context, callbacks };
}

export function useStreamCallback(type, callback) {
  const appCbRef = useContext(AppCallbackContext);

  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    const current = (...args) => callbackRef.current?.(...args);
    appCbRef?.current[type].push(current);

    return () => {
      if (!appCbRef) return;

      appCbRef.current[type] = appCbRef.current[type].filter(
        (callbacks) => callbacks !== current
      );
    };
  }, [type, appCbRef]);
}
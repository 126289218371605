import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  DropdownSection,
  Button,
  Input
} from "@nextui-org/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faMoon } from '@fortawesome/free-solid-svg-icons';

const SearchBox = ({ toggleTheme }) => {
  const [selectedKeys, setSelectedKeys] = useState(new Set(['any']));
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();

  const selectedValue = useMemo(
    () => Array.from(selectedKeys).join(', ').replaceAll('_', ' '),
    [selectedKeys]
  );

  const onSubmit = () => {
    const selectedKey = Array.from(selectedKeys)[0];
    const encodedKeyword = encodeURIComponent(keyword.toLowerCase());
    navigate(`/search/${selectedKey}/${encodedKeyword || 'no_keyword_entered'}/`);
  };

  return (
    <div className="flex items-center justify-between py-2">
      <div className="flex items-center space-x-2 flex-grow">
        <Dropdown>
          <DropdownTrigger>
            <Button color="primary" variant="flat" className="capitalize">
              {selectedValue == "any" ? "Search by " : selectedValue}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Search categories"
            selectionMode="single"
            selectedKeys={selectedKeys}
            onSelectionChange={setSelectedKeys}
          >
            <DropdownSection title="Any" showDivider>
              <DropdownItem key="any">Any</DropdownItem>
            </DropdownSection>
            <DropdownSection title="By plant" showDivider>
              <DropdownItem key="genus">Genus</DropdownItem>
              <DropdownItem key="plant_order">Order</DropdownItem>
              <DropdownItem key="plant_family">Family</DropdownItem>
            </DropdownSection>
            <DropdownSection title="By chemical" showDivider>
              <DropdownItem key="infraclass">Infraclass</DropdownItem>
              <DropdownItem key="chemical_class">Class</DropdownItem>
              <DropdownItem key="chemical_subclass">Subclass</DropdownItem>
            </DropdownSection>
            <DropdownSection title="By usage">
              <DropdownItem key="customary_use">Customary Use</DropdownItem>
            </DropdownSection>
          </DropdownMenu>
        </Dropdown>
        <Input
          placeholder="Genus, Infraclass or Customary Use"
          value={keyword}
          onValueChange={setKeyword}
          className="flex-grow w-full min-w-[20vw]"
        />
        <Button color="success" onClick={onSubmit} className='text-white'>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </Button>
      </div>
      <Button isIconOnly onClick={toggleTheme} className="ml-2">
        <FontAwesomeIcon icon={faMoon} />
      </Button>
    </div>
  );
};

export default React.memo(SearchBox);
import React, {useEffect} from "react";
import { useState, useRef } from "react";

import { AutosizeTextarea } from "./AutosizeTextarea";
import { ChatMessage } from "./ChatMessage";
import { useStreamCallback } from "../hooks/useStreamCallback";
import logo from "../../../assets/LogoWhite_resized.png";
import darkLogo from "../../../assets/LogoBlack_resized.png";
import { ReactComponent as ArrowUp } from "../assets/ArrowUp.svg";
import { ReactComponent as CircleSpinIcon } from "../assets/CircleSpinIcon.svg";
import { ThinkingState } from "./ThinkingState";
import { ExamplePrompt } from "./ExamplePrompt";
import { Link } from "@nextui-org/react";

export function isAIMessage(x) {
  return (
    x != null &&
    typeof x.content === "string" &&
    ["AIMessageChunk", "AIMessage"].includes(x.type)
  );
}

export function ChatWindow(props) {
  const { startStream, messagesInputKey, inputKey, theme } = props;

  const [currentInputValue, setCurrentInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const messageInputRef = useRef(null);

  const submitMessage = (val = "") => {
    let submittedValue = val;
    if(val == ""){
      submittedValue = currentInputValue;
    }
    if (submittedValue.length === 0 || isLoading) {
      return;
    }
    setIsLoading(true);
    const newMessages = [
      ...messages,
      { type: "human", content: submittedValue },
    ];
    setMessages(newMessages);
    setCurrentInputValue("");
    // TODO: Add config schema support
    if (inputKey === undefined) {
      startStream({ [messagesInputKey]: newMessages }, {});
    } else {
      startStream(
        {
          [messagesInputKey]: newMessages.slice(0, -1),
          [inputKey]: newMessages[newMessages.length - 1].content,
        },
        {}
      );
    }
  };

  useStreamCallback("onStart", () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "ai", content: <ThinkingState /> },
    ]);
  });
  useStreamCallback("onChunk", (_chunk, aggregatedState) => {
    const finalOutput = aggregatedState?.final_output;
    if (typeof finalOutput === "string") {
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        { type: "ai", content: finalOutput, runId: aggregatedState?.id },
      ]);
    } else if (isAIMessage(finalOutput)) {
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        {
          type: "ai",
          content: finalOutput.content,
          runId: aggregatedState?.id,
        },
      ]);
    }
  });
  useStreamCallback("onSuccess", () => {
    setIsLoading(false);
  });
  useStreamCallback("onError", (e) => {
    setIsLoading(false);
    setMessages((prevMessages) => [
      ...prevMessages.slice(0, -1),
      {
        type: "ai",
        content:
          "Our AI service is experiencing issues. Please try again later.",
      },
    ]);
  });

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div
      className="flex flex-col grow h-full"
    >
      <div className={"w-100 md:mt-8 h-[calc(100dvh-235px)] flex flex-col z-20 w-full overflow-auto overflow-y-scroll flex-re grow p-4 pb-0 bg-contain bg-no-repeat bg-center bg-opacity-20 no-scrollbar"}
        style={{
          backgroundImage: ((messages.length > 0) ? (theme == "light" ? `url(${logo})`: `url(${darkLogo})`) : "")
        }}>
        {messages.length > 0 ? (
          <div className="flex flex-col-reverse grow">
            <div ref={messagesEndRef} />
            {messages
              .map((message, i) => {
                return <ChatMessage message={message} key={i}></ChatMessage>;
              })
              .reverse()}
              
              
          </div>
        ) : (
          <div className="grow">
            <div
              className={"h-64 bg-contain bg-no-repeat bg-center bg-opacity-60 max-h-[30vh]"}
              style={{
                backgroundImage: (theme == "light" ? `url(${logo})`: `url(${darkLogo})`)
              }}
            />
            <div className="flex flex-col items-center justify-center w-fit mx-auto">
              <h1 className="text-xl text-center">
                Welcome to Customary Medicinal Flora of Australia Database
              </h1>
              <p className="text-center">Search a genus, infraclass or customary use or start chatting</p>
              <div className="w-100 flex flex-col gap-2">
                <ExamplePrompt
                  submitMessage={submitMessage}>
                  {process.env.REACT_APP_EXAMPLE_PROMPT_1 ?? "Describe the chemistry of genus Acacia."}
                </ExamplePrompt>
                <ExamplePrompt
                  submitMessage={submitMessage}
                  className="md:block hidden" >
                  {process.env.REACT_APP_EXAMPLE_PROMPT_2 ?? "Which Australian plants are used for toothache?"}
                </ExamplePrompt>
                <ExamplePrompt
                  submitMessage={submitMessage}
                  className="md:block hidden" >
                  {process.env.REACT_APP_EXAMPLE_PROMPT_3 ?? "What are the therapeutic uses of members of the Myrtaceae?"}
                </ExamplePrompt>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="md:m-16 md:mb-8 m-4 mb-0 flex justify-center flex-col">
        <div
          className="flex items-center p-2 rounded-medium border shadow-sm grow"
          onClick={() => messageInputRef.current?.focus()}
        >
          <AutosizeTextarea
            inputRef={messageInputRef}
            className="flex-grow mx-4 border-none py-2 cursor-text text-ellipsis"
            placeholder="Chat with the assistant..."
            value={currentInputValue}
            onChange={(newValue) => {
              setCurrentInputValue(newValue);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                submitMessage();
              }
            }}
          />
          <button
            className={
              "flex items-center justify-center px-3 py-1 rounded-medium bg-success " +
              (isLoading
                ? ""
                : currentInputValue.length > 0
                ? ""
                : "opacity-60 !cursor-default")
            }
            onClick={(e) => {
              e.preventDefault();
              submitMessage();
            }}
          >
            {isLoading ? (
              <CircleSpinIcon className="animate-spin w-5 h-5 text-background fill-background mx-2 my-2" />
            ) : (
              <ArrowUp className="mx-2 my-2 h-5 w-5 stroke-white" />
            )}
          </button>
        </div>
        <div className="mt-2">
          <p className="text-sm text-gray-400 text-center md:mb-2 mb-1">This is a Generative AI based educational tool which may make mistakes. <Link href="/graph" className="text-sm">See network projection.</Link></p>
        </div>
      </div>
    </div>
  );
}

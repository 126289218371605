import { Panel, PanelHeading, ColorCircle } from "./Graph.styles";
const GraphInfoPanel = () => {
  return (
    <Panel>
      <PanelHeading>Node Colors</PanelHeading>
      <div>
        <ColorCircle color="#6a86bb" />
        <span>Chemical Classes</span>
      </div>
      <div>
        <ColorCircle color="#9c8321" />
        <span>Plants</span>
      </div>
    </Panel>
  );
};
export default GraphInfoPanel;

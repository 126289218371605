import React, { useState } from "react";
import SearchBox from "../SearchBoxMini/SearchBoxMini";
import logo from "../../assets/LogoWhite_resized_2.png";
import darkLogo from "../../assets/LogoBlack_resized_2.png";
import { useNavigate } from "react-router-dom";
import { NavigatePage } from "../../helpers/NavigatePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const ChatHeader = ({ theme, toggleTheme }) => {
  const navigate = useNavigate();
  const logoSource = theme === "dark" ? darkLogo : logo;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="relative">
      <div className="flex justify-between gap-4 md:gap-7 p-2 px-4 flex-row items-center">
        <div
          style={{
            borderRadius: "10%",
            overflow: "hidden",
            margin: "5px",
          }}
          onClick={() => NavigatePage(navigate, "/graph")}
        >
          <img
            src={logoSource}
            alt="Customary Flora of Australia Logo"
            className="w-12 h-auto block"
          />
        </div>
        <div className="hidden md:block">
          <SearchBox toggleTheme={toggleTheme} />
        </div>
        <button className="md:hidden" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} size="lg" />
          <span className="pl-2">Keyword Search</span>
        </button>
      </div>
      {isMenuOpen && (
        <div className="absolute top-full left-0 right-0 light:bg-white dark:bg-gray-800 shadow-md z-30 md:hidden">
          <div className="p-2">
            <SearchBox toggleTheme={toggleTheme} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatHeader;
import { useEffect, useState } from "react";

export function ThinkingState() {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots(currentDots => 
        currentDots.length >= 3 ? "" : currentDots + "."
      );
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return <span>Thinking{dots}</span>;
}
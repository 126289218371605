import { Ref } from "react";
import { cn } from "../utils/cn";
import React from "react";

const COMMON_CLS = cn(
  "text-lg col-[1] row-[1] m-0 resize-none overflow-hidden whitespace-pre-wrap break-words border-none bg-transparent p-0"
);

export function AutosizeTextarea(props) {
  return (
    <div className={cn("grid w-full", props.className) + (props.fullHeight ? "" : " max-h-80 overflow-auto")}>
      <textarea
        ref={props.inputRef}
        id={props.id}
        className={cn(
          COMMON_CLS,
          "text-transparent caret-black focus-visible:outline-none"
        )}
        disabled={props.disabled}
        value={props.value ?? ""}
        rows={1}
        onChange={(e) => {
          props.onChange?.(e.target.value);
        }}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        autoFocus={props.autoFocus && !props.readOnly}
        onKeyDown={props.onKeyDown}
      />
      <div
        aria-hidden
        className={cn(COMMON_CLS, "pointer-events-none select-none")}
      >
        {props.value}{" "}
      </div>
    </div>
  );
}

import { useCallback, useRef, useState } from "react";
import { applyPatch } from "fast-json-patch";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { resolveApiUrl } from "../utils/url";

function reducer(state, action) {
  return applyPatch(state, action, true, false).newDocument;
}

export function useStreamLog(callbacks = {}) {
  const [latest, setLatest] = useState(null);
  const [controller, setController] = useState(null);

  const startRef = useRef(callbacks.onStart);
  startRef.current = callbacks.onStart;

  const chunkRef = useRef(callbacks.onChunk);
  chunkRef.current = callbacks.onChunk;

  const successRef = useRef(callbacks.onSuccess);
  successRef.current = callbacks.onSuccess;

  const errorRef = useRef(callbacks.onError);
  errorRef.current = callbacks.onError;

  const startStream = useCallback(async (input, config) => {
    const controller = new AbortController();
    setController(controller);
    startRef.current?.({ input });

    let innerLatest = null;

    try{
      await fetchEventSource(resolveApiUrl("/stream_log").toString(), {
        signal: controller.signal,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ input, config }),
        onmessage(msg) {
          if (msg.event === "data") {
            innerLatest = reducer(innerLatest, JSON.parse(msg.data)?.ops);
            setLatest(innerLatest);
            chunkRef.current?.(JSON.parse(msg.data), innerLatest);
          }
        },
        openWhenHidden: true,
        onclose() {
          setController(null);
          successRef.current?.({ input, output: innerLatest?.final_output });
        },
        onerror(error) {
          setController(null);
          errorRef.current?.(error);
          throw error;
        },
      });
    }
    catch(e){
      console.error(e);
    }

  }, []);

  const stopStream = useCallback(() => {
    controller?.abort();
    setController(null);
  }, [controller]);

  return {
    startStream,
    stopStream: controller ? stopStream : undefined,
    latest,
  };
}
import Home from "./components/Home/Home";
import GraphPanel from "./components/Graph/GraphPanel";
import "bootstrap/dist/css/bootstrap.min.css";
import { SearchResultsWrapper } from "./helpers/NavigatePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import useDarkMode from "./hooks/useDarkMode";
import ChatPanel from "./components/Chat/ChatPanel";

const App = () => {
  const [theme, toggleTheme] = useDarkMode();
  return (
    <div className={`${theme} text-foreground bg-background`}>
      <Router>
        <Routes>
          {/* <Route
            path="/"
            element={<Home theme={theme} toggleTheme={toggleTheme} />}
          /> */}
          <Route
            path="/"
            element={<ChatPanel theme={theme} toggleTheme={toggleTheme} />}
          />
          <Route
            path="/graph"
            element={<GraphPanel theme={theme} toggleTheme={toggleTheme} />}
          />
          <Route
            path="/search/:filter/:keyword/"
            element={
              <SearchResultsWrapper theme={theme} toggleTheme={toggleTheme} />
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;

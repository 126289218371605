import styled from "@emotion/styled";

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;
`;

const GraphItem = styled.div`
  display: inline-block;
`;

const GraphButtonContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: scroll;
`;

const CardWrapper = styled.div`
  margin: 10px;
  max-width: 1000px;
  flex: 1;
  min-height: 600px;
`;

const Panel = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 10;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
`;

const PanelHeading = styled.h4`
  margin-top: 0;
`;

const ColorCircle = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`;

export {
  GraphContainer,
  GraphButtonContainer,
  GraphItem,
  CardWrapper,
  PanelHeading,
  Panel,
  ColorCircle,
};

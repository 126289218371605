import React from 'react';

export function ChatMessage(props) {
  const { message, fullHeight } = props;
  const { content, type } = message;

  const isHuman = type === "human";
  const name = isHuman ? "You" : (process.env.REACT_APP_AI_NAME ?? "Assistant");

  return (
    <div className={`mb-8 group flex ${isHuman ? "justify-end" : "justify-start"} z-50 bg-inherit`}>
      <div className={`relative w-fit max-w-[80%] p-4 ${isHuman ? "bg-[#daf5e2]" : "bg-[#d3e2fc]"} rounded-medium ${isHuman ? "rounded-br-none" : "rounded-bl-none"} shadow-md`}>
        {/* Name */}
        <div className="flex items-center">
          <p className={"font-semibold mb-0 " + (isHuman ? "text-success " : "text-primary")}>{name}</p>
        </div>
        {/* Content */}
        <div className={`w-full ${fullHeight ? "" : " overflow-auto"}`}>
          <div
            aria-hidden
            className="whitespace-pre-line text-black"
          >
            {content}
          </div>
        </div>
        {/* Cloud tail shape */}
        <div
          className={`absolute bottom-0 ${isHuman ? "right-0 -mr-2 rotate-90 bg-success/20" : "left-0 -ml-2 -rotate-90 bg-primary/20"} h-2 w-2 shadow-md`}
          style={{
            clipPath: isHuman
              ? 'polygon(100% 0%, 0% 100%, 100% 100%)'
              : 'polygon(0% 0%, 0% 100%, 100% 100%)',
          }}
        ></div>
      </div>
    </div>
  );
}

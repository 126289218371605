import React from "react";
import { CentreCard } from "../Loading/Loading.styles";
import { Button, Card, CardBody, CardHeader } from "@nextui-org/react";
import { CardHeaderText } from "../SearchPanel/SearchPanelStyles";
import { useNavigate } from "react-router-dom";
import { NavigatePage } from "../../helpers/NavigatePage";

const Error = ({
  errorText = "There has been an error obtaining the data",
}) => {
  const navigate = useNavigate();
  return (
    <CentreCard>
      <Card>
        <CardHeader>
          <CardHeaderText>{errorText}</CardHeaderText>
        </CardHeader>
        <CardBody>
          <Button color="danger" onClick={() => NavigatePage(navigate, "/")}>
            Go back to the Landing Page
          </Button>
        </CardBody>
      </Card>
    </CentreCard>
  );
};

export default Error;
